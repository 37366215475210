@font-face {
    font-family: 'Source Sans Pro';
    src: url(./SourceSansPro/SourceSansPro-Bold.ttf) format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url(./SourceSansPro/SourceSansPro-SemiBold.ttf) format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url(./SourceSansPro/SourceSansPro-Regular.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url(./SourceSansPro/SourceSansPro-Light.ttf) format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url(./SourceSansPro/SourceSansPro-ExtraLight.ttf) format('truetype');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}
