@font-face {
    font-family: 'Times New Roman';
    src: url(./TimesNewRoman/TimesNewRoman-Bold.ttf) format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Times New Roman';
    src: url(./TimesNewRoman/TimesNewRoman-Regular.ttf) format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
