@font-face {
    font-family: 'Open Sans';
    src: url(./OpenSans/OpenSans-ExtraBold.ttf) format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url(./OpenSans/OpenSans-Bold.ttf) format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url(./OpenSans/OpenSans-SemiBold.ttf) format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url(./OpenSans/OpenSans-Regular.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url(./OpenSans/OpenSans-Light.ttf) format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}
