@font-face {
    font-family: 'Lato';
    src: url(./Lato/Lato-Black.ttf) format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lato';
    src: url(./Lato/Lato-Bold.ttf) format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lato';
    src: url(./Lato/Lato-Regular.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lato';
    src: url(./Lato/Lato-Light.ttf) format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lato';
    src: url(./Lato/Lato-Thin.ttf) format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}
