@font-face {
    font-family: 'Work Sans';
    src: url(./WorkSans/WorkSans-ExtraBold.ttf) format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Work Sans';
    src: url(./WorkSans/WorkSans-Bold.ttf) format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Work Sans';
    src: url(./WorkSans/WorkSans-SemiBold.ttf) format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Work Sans';
    src: url(./WorkSans/WorkSans-Medium.ttf) format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Work Sans';
    src: url(./WorkSans/WorkSans-Regular.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Work Sans';
    src: url(./WorkSans/WorkSans-Light.ttf) format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Work Sans';
    src: url(./WorkSans/WorkSans-ExtraLight.ttf) format('truetype');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Work Sans';
    src: url(./WorkSans/WorkSans-Thin.ttf) format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}
