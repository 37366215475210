@font-face {
    font-family: 'Calibri';
    src: url(./Calibri/Calibri-Bold.TTF) format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Calibri';
    src: url(./Calibri/Calibri-Light.ttf) format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Calibri';
    src: url(./Calibri/Calibri-Regular.ttf) format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
