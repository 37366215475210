@font-face {
    font-family: 'Quicksand';
    src: url(./Quicksand/Quicksand-Regular.ttf) format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url(./Montserrat/Montserrat-Black.ttf) format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url(./Montserrat/Montserrat-Bold.ttf) format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url(./Montserrat/Montserrat-SemiBold.ttf) format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url(./Montserrat/Montserrat-Medium.ttf) format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url(./Montserrat/Montserrat-Regular.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url(./Montserrat/Montserrat-Light.ttf) format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url(./Montserrat/Montserrat-ExtraLight.ttf) format('truetype');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url(./Montserrat/Montserrat-Thin.ttf) format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Baloo 2';
    src: url(./Baloo2/Baloo2-ExtraBold.ttf) format('truetype');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Baloo 2';
    src: url(./Baloo2/Baloo2-Bold.ttf) format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Baloo 2';
    src: url(./Baloo2/Baloo2-SemiBold.ttf) format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Baloo 2';
    src: url(./Baloo2/Baloo2-Medium.ttf) format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Baloo 2';
    src: url(./Baloo2/Baloo2-Regular.ttf) format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url(./Poppins/Poppins-Regular.ttf) format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
