@font-face {
    font-family: 'Arial';
    src: url(./Arial/Arial-Regular.ttf) format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Arial';
    src: url(./Arial/Arial-Bold.ttf) format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Arial';
    src: url(./Arial/Arial-Thin.ttf) format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}
