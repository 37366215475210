@font-face {
    font-family: 'Helvetica';
    src: url(./Helvetica/Helvetica-Bold.ttf) format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica';
    src: url(./Helvetica/Helvetica-BoldOblique.ttf) format('truetype');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica';
    src: url(./Helvetica/Helvetica-Compressed.otf) format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica';
    src: url(./Helvetica/Helvetica-Light.ttf) format('truetype');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica';
    src: url(./Helvetica/Helvetica-Oblique.ttf) format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica';
    src: url(./Helvetica/Helvetica-Regular.ttf) format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica';
    src: url(./Helvetica/Helvetica-RoundedBold.otf) format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}
