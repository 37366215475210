.pallets {
    & .pallet {
        width: 100%;
        height: 48px;
        cursor: pointer;
        border-radius: 4px;
        display: flex;
        position: relative;

        //& > div {
        //  border: 1px solid #d0d3d9;
        //}

        & .first {
            width: 184px;
            height: 100%;
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
        }

        & .second {
            width: 92px;
            height: 100%;
        }

        & .third {
            width: 46px;
            height: 100%;
        }

        & .fourth {
            width: 48px;
            height: 100%;
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
        }
    }
}

.text {
    font-weight: 400;
    font-size: 14px;
    color: #233353;
    margin-bottom: 16px;
}

.color-picker {
    padding: 12px;
    background-color: white;
    position: absolute;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    top: 60px;
    width: 100%;

    &__input__container {
        position: relative;
        margin-top: 16px;
    }

    &__color {
        position: absolute;
        width: 24px;
        height: 24px;
        border-radius: 4px;
        right: 10px;
        top: 10px;
    }

    &__input {
        border: 1px solid #d0d3d9;
        border-radius: 4px;
        padding: 12px 16px;
        font-size: 14px;
        color: #233353;
        width: 100%;

        &:focus {
            outline: none;
        }
    }
}

.footer {
    display: flex;
    gap: 16px;
    justify-content: flex-end;
}

.container {
    min-height: 370px;
}
