@font-face {
    font-family: 'Crimson Text';
    src: url(./CrimsonText/CrimsonText-Bold.ttf) format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Crimson Text';
    src: url(./CrimsonText/CrimsonText-SemiBold.ttf) format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Crimson Text';
    src: url(./CrimsonText/CrimsonText-Regular.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
